import { gql } from '@apollo/client';


export const GET_APARTAMENTOS_QUERY = gql`
  query GetApartamentos {
    apartamentos {
      id
      calle
      numero
      num_apto
      qrCodeUrl
      documentoUrl
    }
  }
`;

export const GET_APARTAMENTO_QUERY = gql`
  query GetApartamento($id: ID!) {
    apartamento(id: $id) {
      id
      calle
      numero
      num_apto
      qrCodeUrl
      documentoUrl
    }
  }
`;

export const DELETE_APARTAMENTO_QUERY = gql`
  mutation eliminarApartamento($id: ID!) {
    eliminarApartamento(idApartamento : $id) {
      message
      success
    }
  }
`

export const GET_APARTAMENTO = gql`
  query GetApartamento($id: ID!) {
    apartamento(id: $id) {
      id
      calle
      numero
      num_apto
    }
  }
`;

export const EDITAR_ADDRESS_APARTAMENTO = gql`
  mutation EditarAddressApartamento($idApartamento: ID!, $inputApartamento: InputApartamento!) {
    editarAddressApartamento(idApartamento: $idApartamento, inputApartamento: $inputApartamento) {
      id
      calle
      numero
      num_apto
    }
  }
`;

export const CREAR_APARTAMENTO_MUTATION = gql`
  mutation CrearApartamento($calle: String!, $numero: Int!, $num_apto: String!) {
    crearApartamento(calle: $calle, numero: $numero, num_apto: $num_apto) {
      id
      calle
      numero
      num_apto
    }
  }
`;

export const UPLOAD_FILE_MUTATION = gql`
  mutation subirArchivo($id:ID!, $file: Upload!) {
    subirArchivo(id:$id,file: $file) {
      success
      message
      fileUrl
    }
  }
`


export const DELETE_FILE_MUTATION = gql`
  mutation eliminarArchivo($id:ID!) {
    eliminarArchivo(id:$id) {
      success
      message
    }
  }
`
