import React from 'react';
import '../../Styles/SideBar.css';
import userIcon from '../../users.svg';
import bussinesIcon from '../../business.svg';
import notifyIcon from '../../notifyIcon.svg';
import buildingIcon from '../../buildings.svg';
import SignOut from '../SignOut/index';
import { Link } from 'react-router-dom';

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="menu">
          <Link to="/unidades">
          <img src={buildingIcon} alt='building icon' className='icon'></img> 
          <span style={{margin:'5px'}}>Unidades</span>
          </Link>
          <Link to="/empresas">
            <img src={bussinesIcon} alt='Bsns icon' className='icon'></img> 
            <span style={{margin:'5px'}}>Empresas</span>
          </Link>
          <Link to="/personas" className="icon-text">
            <img src={userIcon} alt="User Icon" className="icon" />
            <span style={{margin:'5px'}}>Personas</span>
          </Link>
          <Link to="/historial">
            <img src={notifyIcon} alt="Nfy Icon" className="icon" /> 
            <span style={{margin:'5px'}}>Inspecciones</span>
          </Link>
        </div>
        <SignOut />
      </div>
      <button className={` ${isSidebarOpen ? 'toggle-button' : 'toggle-button closed'}`} onClick={toggleSidebar}>
        {<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30"
          style={{ fill: '#9b9ca7' }}>
          <path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"></path>
        </svg>}
      </button>
    </>
  );
};

export default Sidebar;
