import { gql } from "@apollo/client"

export const GET_INVENTARIO = gql`
  query GetInventario($apartamentoId: ID!) {
    inventario(apartamentoId: $apartamentoId) {
      _id
      nombre
      cantidad
      cantidad_min
      lugar
      ambienteId {
        _id
        nombre
      }
    }
  }
`;

export const CREAR_INVENTARIO = gql`
  mutation CrearInventario($ambienteId: ID!, $apartamentoId: ID!, $inputInventario: InputInventario!) {
    crearInventario(ambienteId: $ambienteId, apartamentoId: $apartamentoId, inputInventario: $inputInventario) {
      nombre
      cantidad
      cantidad_min
      lugar
    }
  }
`;

export const EDITAR_INVENTARIO = gql`
  mutation EditarInventario($inventarioId: ID!, $inputInventario: InputInventario!) {
    editarInventario(inventarioId:$inventarioId, inputInventario: $inputInventario) {
      nombre
      cantidad
      cantidad_min
      lugar
    }
  }
`;

export const ELIMINAR_INVENTARIO = gql`
  mutation EliminarInventario($inventarioId: ID!) {
    eliminarInventario(inventarioId: $inventarioId) {
      success
      message
    }
  }
`;

