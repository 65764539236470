import React from 'react';
import Sidebar from './sidebar.js';
import '../../Styles/Layout.css';
import { useState } from 'react';

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="layout">
      <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      <div className={`protected-content ${isSidebarOpen ? 'modalOpen' : ''}`}>
        {children}
      </div>
      {isSidebarOpen && <div className="overlay" onClick={() => setIsSidebarOpen(false)}></div>}
      <footer className="footer">
        <p>schmidtmaxi46@gmail.com</p>
        <p>|</p>
        <p>+598 98 660 754</p>
      </footer>
    </div>
  );
};

export default Layout;
