import { gql } from '@apollo/client';

export const CREAR_AMBIENTE = gql`
  mutation CrearAmbiente($apartamentoId: ID!, $inputAmbiente: InputAmbiente!) {
    crearAmbiente(apartamentoId: $apartamentoId, inputAmbiente: $inputAmbiente) {
      _id
      nombre
      detalles
    }
  }
`;

export const ELIMINAR_AMBIENTE = gql`
  mutation EliminarAmbiente($ambienteId: ID!) {
    eliminarAmbiente(ambienteId: $ambienteId) {
      success
      message
    }
  }
`;

export const EDITAR_AMBIENTE = gql`
  mutation EditarAmbiente($ambienteId: ID!, $inputAmbiente: InputAmbiente!) {
    editarAmbiente(ambienteId: $ambienteId, inputAmbiente: $inputAmbiente) {
      _id
      nombre
      detalles
    }
  }
`;