import React, { useEffect, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import LayoutNBItems from './LayoutNBItems';
import Modal from '../Modal';
import { GET_AMBIENTES } from '../../Queries/ambientes';
import { useParams } from 'react-router-dom';
import { GET_TAREAS, CREAR_TAREA, EDITAR_TAREA, ELIMINAR_TAREA } from '../../Queries/tareas';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

const TaskList = ({ idApartamento }) => {
  const { id } = useParams();
  const apartmentId = idApartamento || id;

  const { data: dataAmbientes, loading: ambientesLoading, error: ambientesError, refetch: refetchAmbientes } = useQuery(GET_AMBIENTES, {
    variables: { id: apartmentId },
  });

  const { data, loading: queryLoading, error: queryError, refetch } = useQuery(GET_TAREAS, {
    variables: { id: apartmentId },
  });

  const [crearTarea] = useMutation(CREAR_TAREA, { onCompleted: () => refetch() });
  const [editarTarea] = useMutation(EDITAR_TAREA, { onCompleted: () => refetch() });
  const [eliminarTarea] = useMutation(ELIMINAR_TAREA, { onCompleted: () => refetch() });

  const [ambienteSeleccionado, setAmbienteSeleccionado] = useState('');
  const [tareas, setTareas] = useState([]);
  const [tarea, setTarea] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [oculta, setOculta] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [filtroAmbiente, setFiltroAmbiente] = useState('');
  const [filtroOculta, setFiltroOculta] = useState('');

  useEffect(() => {
    refetchAmbientes();
    refetch();
    if (dataAmbientes && dataAmbientes.ambientes) {
      console.log("Ambientes disponibles:", dataAmbientes.ambientes);
    }
    if (data && data.tareas) {
      console.log("Tareas recibidas:", data.tareas); // Depurar tareas
      setTareas(data.tareas);
    }
  }, [data, dataAmbientes]);


  const tareasFiltradas = tareas
    .filter((task) => {
      console.log("Tarea actual:", task); // Depurar cada tarea
      if (filtroAmbiente) {
        const ambienteId = task.ambienteId?._id; // Verificar el ambiente
        console.log("Comparando:", ambienteId, "con", filtroAmbiente);
        return ambienteId && String(ambienteId) === String(filtroAmbiente);
      }
      return true;
    })
    .filter((task) => (filtroOculta === 'oculta' ? task.oculta : true));


  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setTarea('');
    setDescripcion('');
    setOculta(false);
    setAmbienteSeleccionado('');
    setEditingId(null);
    setModalOpen(false);
  };

  const handleEdit = (tarea) => {
    setEditingId(tarea._id);
    setTarea(tarea.nombre);
    setDescripcion(tarea.descripcion);
    setOculta(tarea.oculta);
    setAmbienteSeleccionado(tarea.ambienteId?._id);
    openModal();
  };

  const handleDelete = (id) => {
    eliminarTarea({ variables: { tareaId: id } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Calcular el orden como el último índice más uno
    const nuevoOrden = tareas.length;
  
    const variables = {
      inputTarea: {
        ambienteId: ambienteSeleccionado,
        apartamentoId: apartmentId,
        nombre: tarea,
        descripcion,
        oculta,
        orden: nuevoOrden
      },
    };
  
    if (editingId) {
      editarTarea({ variables: { tareaId: editingId, ...variables } });
    } else {
      crearTarea({ variables });
    }
  
    closeModal();
  };
  

  const handleOrderChange = (index, direction) => {
    setTareas((prevTareas) => {
      const tareasActualizadas = [...prevTareas]; // Crea una copia del arreglo
      if (direction === 'up' && index > 0) {
        // Intercambiar posición con la tarea anterior
        [tareasActualizadas[index - 1], tareasActualizadas[index]] = [
          tareasActualizadas[index],
          tareasActualizadas[index - 1],
        ];
      } else if (direction === 'down' && index < prevTareas.length - 1) {
        // Intercambiar posición con la tarea siguiente
        [tareasActualizadas[index], tareasActualizadas[index + 1]] = [
          tareasActualizadas[index + 1],
          tareasActualizadas[index],
        ];
      }

      console.log("tareas:",tareas);
  
      // Actualizar la base de datos con los nuevos valores de orden
      tareasActualizadas.forEach((tarea, newIndex) => {
        editarTarea({
          variables: {
            tareaId: tarea._id,
            inputTarea: {
              nombre: tarea.nombre,
              descripcion: tarea.descripcion,
              apartamentoId: tarea.apartamentoId.id,
              ambienteId: tarea.ambienteId._id,
              oculta: tarea.oculta,
              orden: newIndex, // Actualiza el campo orden
            },
          },
        });
      });
      console.log("tareasActualizadas:",tareasActualizadas);
  
      return tareasActualizadas; // Devuelve la nueva lista para actualizar el estado
    });
  };
  

  return (
    <LayoutNBItems>
      <Typography variant="h4">Listado de tareas</Typography>

      {ambientesLoading || queryLoading ? (
        <Typography>Cargando...</Typography>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={openModal} style={{ marginBottom: '20px' }}>
            Nuevo
          </Button>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tarea</TableCell>
                  <TableCell>
                    <Select
                      value={filtroAmbiente || ''}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        console.log("Valor seleccionado en el filtro:", selectedValue);
                        setFiltroAmbiente(selectedValue);
                      }}
                      displayEmpty
                      style={{ minWidth: '150px' }}
                    >
                      <MenuItem value="">Todos los ambientes</MenuItem>
                      {dataAmbientes?.ambientes?.map((ambiente) => (
                        <MenuItem key={ambiente._id} value={String(ambiente._id)}>
                          {ambiente.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filtroOculta === 'oculta'}
                          onChange={(e) => setFiltroOculta(e.target.checked ? 'oculta' : '')}
                          color="primary"
                        />
                      }
                      label="Oculta"
                    />
                  </TableCell>
                  <TableCell>Orden</TableCell> {/* Nueva columna para las flechas */}
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tareasFiltradas.length > 0 ? (
                  tareasFiltradas.map((task, index) => (
                    <TableRow key={task._id}>
                      <TableCell>{task.nombre}</TableCell>
                      <TableCell>{task.ambienteId?.nombre || 'Sin ambiente'}</TableCell>
                      <TableCell>{task.oculta ? 'Sí' : 'No'}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => handleOrderChange(index, 'up')}
                          disabled={index === 0}
                        >
                          ⬆
                        </Button>
                        <Button
                          onClick={() => handleOrderChange(index, 'down')}
                          disabled={index === tareasFiltradas.length - 1}
                        >
                          ⬇
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => handleEdit(task)}>Editar</Button>
                        <Button onClick={() => handleDelete(task._id)} color="secondary">
                          Eliminar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography>No hay tareas creadas aún</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

        </>
      )}

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h6">{editingId ? 'Editar Tarea' : 'Crear Tarea'}</Typography>
          <TextField
            fullWidth
            label="Tarea"
            value={tarea}
            onChange={(e) => setTarea(e.target.value)}
            placeholder="Nombre de la tarea"
            style={{ marginBottom: '10px' }}
          />
          <TextField
            fullWidth
            label="Descripción"
            multiline
            rows={4}
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            placeholder="Descripción de la tarea"
            style={{ marginBottom: '10px' }}
          />
          <Select
            fullWidth
            value={ambienteSeleccionado || ''}
            onChange={(e) => setAmbienteSeleccionado(e.target.value)}
            displayEmpty
            style={{ marginBottom: '10px' }}
          >
            <MenuItem value="">Seleccione un ambiente</MenuItem>
            {dataAmbientes?.ambientes?.map((ambiente) => (
              <MenuItem key={ambiente._id} value={ambiente._id}>
                {ambiente.nombre}
              </MenuItem>
            ))}
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!oculta}
                onChange={(e) => setOculta(e.target.checked)}
                color="primary"
              />
            }
            label="Ocultar tarea"
            style={{ marginBottom: '10px' }}
          />
          <Button variant="contained" color="primary" type="submit">
            {editingId ? 'Guardar Cambios' : 'Crear Tarea'}
          </Button>
          <Button
            variant="outlined"
            onClick={closeModal}
            style={{ marginLeft: '10px' }}
          >
            Cancelar
          </Button>
        </form>
      </Modal>
    </LayoutNBItems>
  );
};

export default TaskList;
