// Layout.js
import React from 'react';
import '../../Styles/LayoutNBItems.css';

const LayoutNBItems = ({ children }) => {
  return (
      <div className="layoutItem">
        {children}
      </div>
  );
};

export default LayoutNBItems;
