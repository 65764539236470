import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';

const SignOut = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    // Borra el token de localStorage
    localStorage.removeItem('token');

    // Redirige a la página de inicio de sesión
    navigate('/');
  }

  return (
    <button onClick={handleSignOut} className={styles.signOutbtn}>
      Salir
    </button>
  )
}

export default SignOut;
