import React, { useEffect, useState } from 'react';
import LayoutNBItems from './LayoutNBItems';
import { useQuery, useMutation } from '@apollo/client';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { Link } from 'react-router-dom';
import { GET_ALL_INSPECCIONES, ELIMINAR_INSPECCIONES } from '../../Queries/Inspeccion';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Box,
} from '@mui/material';

const Historial = () => {
  const [inspecciones, setInspecciones] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filtroApartamento, setFiltroApartamento] = useState('');
  const [filtroPersona, setFiltroPersona] = useState('');
  const [filtroEstado, setFiltroEstado] = useState('');
  const [filtroInicio, setFiltroInicio] = useState('');
  const [eliminar, setAEliminar] = useState([]);

  const { data, loading, refetch } = useQuery(GET_ALL_INSPECCIONES);
  const [eliminarInspeccion] = useMutation(ELIMINAR_INSPECCIONES);

  useEffect(() => {
    refetch();
    if (data && data.inspecciones) {
      setInspecciones(data.inspecciones);
    }
  }, [data, refetch]);

  const calcularDuracionEnHoras = (inicio, fin) => {
    const diferenciaMilisegundos = fin - inicio;
    const horas = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60));
    const minutos = Math.floor((diferenciaMilisegundos % (1000 * 60 * 60)) / (1000 * 60));
    const segundos = Math.floor((diferenciaMilisegundos % (1000 * 60)) / 1000);
    return `${horas} hrs ${minutos} mins ${segundos} secs`;
  };

  const formatDate = (date, timeZone) => {
    const zonedDate = toZonedTime(date, timeZone);
    return formatInTimeZone(zonedDate, timeZone, 'yyyy-MM-dd HH:mm');
  };

  const timeZone = 'America/Montevideo';

  const uniqueApartamentos = [...new Set(inspecciones.map(i => `${i.apartamento.calle} ${i.apartamento.numero}`))];
  const uniquePersonas = [...new Set(inspecciones.map(i => i.persona?.email || i.empleado?.nombre))];
  const uniqueEstados = ['En progreso', 'Realizada'];

  const inspeccionesFiltradas = inspecciones.filter(inspeccion => {
    const t_i = inspeccion.inicio ? new Date(parseInt(inspeccion.inicio, 10)) : null;
    const inicioFormateado = t_i ? formatDate(t_i, timeZone) : '';
    return (
      (filtroApartamento === '' || `${inspeccion.apartamento.calle} ${inspeccion.apartamento.numero}` === filtroApartamento) &&
      (filtroPersona === '' || (inspeccion.persona?.email === filtroPersona || inspeccion.empleado?.nombre === filtroPersona)) &&
      (filtroEstado === '' ||
        (inspeccion.inicio && !inspeccion.fin && filtroEstado === 'En progreso') ||
        (inspeccion.inicio && inspeccion.fin && filtroEstado === 'Realizada')) &&
      (filtroInicio === '' || inicioFormateado.startsWith(filtroInicio))
    );
  });

  const handleEliminar = async () => {
    try {
      await eliminarInspeccion({ variables: { inspecciones: eliminar } });
      refetch(); // Refrescar la lista de inspecciones
      setAEliminar([]); // Limpiar la lista de elementos seleccionados
    } catch (error) {
      console.error('Error al eliminar las inspecciones:', error);
    }
  };

  return (
    <LayoutNBItems>
      <h1>Inspecciones</h1>
      {eliminar.length > 0 && (
        <Button color="error" onClick={handleEliminar} style={{ margin: '5px' }}>
          Eliminar Seleccionados
        </Button>
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>
            <Box mr={1}>
              <Select
                value={filtroApartamento}
                onChange={(e) => setFiltroApartamento(e.target.value)}
                size="small"
                displayEmpty
              >
                <MenuItem value="" disabled>Apartamento</MenuItem>
                {uniqueApartamentos.map((apto, index) => (
                  <MenuItem key={index} value={apto}>{apto}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box mr={1}>
              <Select
                value={filtroPersona}
                onChange={(e) => setFiltroPersona(e.target.value)}
                size="small"
                displayEmpty
              >
                <MenuItem value="" disabled>Persona</MenuItem>
                {uniquePersonas.map((persona, index) => (
                  <MenuItem key={index} value={persona}>{persona}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box mr={1}>
              <Select
                value={filtroEstado}
                onChange={(e) => setFiltroEstado(e.target.value)}
                size="small"
                displayEmpty
              >
                <MenuItem value="" disabled>Estado</MenuItem>
                {uniqueEstados.map((estado, index) => (
                  <MenuItem key={index} value={estado}>{estado}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box mr={1}>
              <TextField
                type="date"
                value={filtroInicio}
                onChange={(e) => setFiltroInicio(e.target.value)}
                size="small"
                placeholder="Inicio"
              />
            </Box>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={eliminar.length > 0 && eliminar.length < inspeccionesFiltradas.length}
                    checked={eliminar.length === inspeccionesFiltradas.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setAEliminar(inspeccionesFiltradas.map(i => i._id));
                      } else {
                        setAEliminar([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>Apartamento</TableCell>
                <TableCell>Persona</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Inicio</TableCell>
                <TableCell>Fin</TableCell>
                <TableCell>Duración</TableCell>
                <TableCell>Detalles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inspeccionesFiltradas.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((inspeccion, index) => {
                let t_i = parseInt(inspeccion.inicio, 10);
                let t_ini = new Date(t_i);
                let t_f = parseInt(inspeccion.fin, 10);
                let t_fin = new Date(t_f);
                return (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAEliminar((prev) => [...prev, inspeccion._id]);
                          } else {
                            setAEliminar((prev) => prev.filter((id) => id !== inspeccion._id));
                          }
                        }}
                        checked={eliminar.includes(inspeccion._id)}
                      />
                    </TableCell>
                    <TableCell>{`${inspeccion.apartamento.calle} ${inspeccion.apartamento.numero} | `}<span style={{ padding: '2px', borderRadius: '5px', color: 'white', backgroundColor: "black", fontWeight: '400' }}>{inspeccion.apartamento.num_apto}</span></TableCell>
                    <TableCell>{inspeccion.persona ? inspeccion.persona.email : inspeccion.empleado ? inspeccion.empleado.nombre : ''}</TableCell>
                    <TableCell>{
                      inspeccion.inicio && !inspeccion.fin ? 'En progreso' : !inspeccion.inicio ? 'Pendiente' : 'Realizada'
                    }</TableCell>
                    <TableCell>{inspeccion.inicio ? formatDate(t_ini, timeZone) : '-'}</TableCell>
                    <TableCell>{inspeccion.fin ? formatDate(t_fin, timeZone) : '-'}</TableCell>
                    <TableCell>{inspeccion.inicio && inspeccion.fin ? calcularDuracionEnHoras(t_ini, t_fin) : '-'}</TableCell>
                    <TableCell><Link to={`${inspeccion._id}`}>Ver</Link></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={inspeccionesFiltradas.length}
            rowsPerPage={itemsPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setItemsPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </TableContainer>
      )}
    </LayoutNBItems>
  );
};

export default Historial;
