import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login(email: $username, password: $password) {
      token
    }
  }
`;

export const VERIFY_TOKEN_QUERY = gql`
  query {
    protectedData {
      secret
    }
  }
`;