import React from 'react';
import ListadoApartamentos from '../ListadoApartamentos';

function Protected() {


  return (
    <>
        <ListadoApartamentos />
    </>
  );
}

export default Protected;
