import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { GET_APARTAMENTOS_QUERY, DELETE_APARTAMENTO_QUERY, UPLOAD_FILE_MUTATION, DELETE_FILE_MUTATION } from '../../Queries/apartamentos';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Typography } from '@mui/material';
import { Home as HomeIcon, QrCode as QrCodeIcon, Edit as EditIcon, Delete as DeleteIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';

function ListadoApartamentos() {
  const [isQrModalOpen, setQrModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isFileModalOpen, setFileModalOpen] = useState(false);
  const [selectedQrCodeUrl, setSelectedQrCodeUrl] = useState('');
  const [selectedAptoDelete, setSelectedAptoDelete] = useState('');
  const [selectedAptoFile, setSelectedAptoFile] = useState(null); // Estado para gestionar el archivo del apartamento
  const [file, setFile] = useState(null); // Para manejar la subida del archivo
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(GET_APARTAMENTOS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const [deleteApartamento, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_APARTAMENTO_QUERY, {
    onCompleted: (data) => {
      if (data.eliminarApartamento.success) {
        closeDeleteModal();
        refetch();
      } else {
        console.error(data.eliminarApartamento.message);
      }
    },
  });

  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION, {
    onCompleted: () => {
      refetch();
      closeFileModal();
    },
  });

  const [deleteFile] = useMutation(DELETE_FILE_MUTATION, {
    onCompleted: () => {
      refetch();
      closeFileModal();
    },
  });

  const handleDeleteApartamento = () => {
    deleteApartamento({ variables: { id: selectedAptoDelete } });
  };

  const handleUploadFile = () => {
    if (!file) return;

    // Busca el apartamento para ver si tiene un archivo asociado (fileUrl)
    const apartamento = data.apartamentos.find(apto => apto.id === selectedAptoFile);

    if (apartamento && apartamento.documentoUrl) {
      // Si hay un archivo previo, lo eliminamos antes de subir el nuevo
      deleteFile({ variables: { id: selectedAptoFile } })
        .then(() => {
          // Después de eliminar el archivo previo, subimos el nuevo archivo
          uploadFile({ variables: { id: selectedAptoFile, file: file } });
        })
        .catch(error => {
          console.error('Error al eliminar el archivo anterior:', error);
        });
    } else {
      // Si no hay archivo previo, simplemente subimos el nuevo
      uploadFile({ variables: { id: selectedAptoFile, file: file } });
    }
  };


  const handleDeleteFile = () => {
    deleteFile({ variables: { id: selectedAptoFile } });
  };

  const openFileModal = (apartamento) => {
    setSelectedAptoFile(apartamento.id);
    setFile(apartamento.documentoUrl);
    setFileModalOpen(true);
  };

  const closeFileModal = () => {
    setFileModalOpen(false);
    setSelectedAptoFile(null);
    setFile(null);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  const openQrModal = (qrCodeUrl) => {
    setSelectedQrCodeUrl(qrCodeUrl);
    setQrModalOpen(true);
  };

  const openDeleteModal = (idApto) => {
    setSelectedAptoDelete(idApto);
    setDeleteModalOpen(true);
  };

  const closeQrModal = () => {
    setQrModalOpen(false);
    setSelectedQrCodeUrl('');
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedAptoDelete('');
  };

  const redirect = (path) => {
    navigate(path);
  };

  const downloadQRCode = (qrCodeUrl) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = qrCodeUrl;
    downloadLink.download = 'codigo_qr.png';
    downloadLink.click();
  };

  return (
    <div className="layoutItem listadoAptos">
      <Button
        variant="contained"
        startIcon={<HomeIcon />}
        component={Link}
        to="/crearApartamento"
        sx={{ mb: 2 }}
      >
        Nueva Unidad
      </Button>

      {data.apartamentos.length === 0 ? (
        <Typography>No hay apartamentos creados todavía.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Dirección</TableCell>
                <TableCell>Apartamento</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.apartamentos.map((apartamento, index) => (
                <TableRow key={index}>
                  <TableCell>{apartamento.calle} {apartamento.numero}</TableCell>
                  <TableCell>{apartamento.num_apto}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => openQrModal(apartamento.qrCodeUrl)}>
                      <QrCodeIcon />
                    </IconButton>
                    <IconButton onClick={() => redirect(`/editarApartamento/${apartamento.id}`)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => openDeleteModal(apartamento.id)}>
                      <DeleteIcon />
                    </IconButton>
                    {/* Nuevo botón para manejar archivos */}
                    <IconButton onClick={() => openFileModal(apartamento)}>
                      <AttachFileIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={isQrModalOpen} onClose={closeQrModal}>
        <DialogTitle>Código QR</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img src={selectedQrCodeUrl} alt="Código QR" style={{ width: '100%' }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => downloadQRCode(selectedQrCodeUrl)}>Descargar</Button>
          <Button onClick={closeQrModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
        <DialogTitle>Eliminar Apartamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Seguro que quieres eliminar la unidad? Se perderán los datos asociados, incluyendo las inspecciones realizadas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteApartamento} disabled={deleteLoading}>
            {deleteLoading ? <CircularProgress size={24} /> : 'Sí, eliminar.'}
          </Button>
          <Button onClick={closeDeleteModal} color='error'>No, cancelar.</Button>
        </DialogActions>
        {deleteError && <Typography color="error">{deleteError.message}</Typography>}
      </Dialog>

      {/* Modal para subir o eliminar archivo */}
      <Dialog open={isFileModalOpen} onClose={closeFileModal}>
        <DialogTitle>Gestionar Documento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {file ? (
              <>
                Documento actual{' '}
                <a
                  href={`${String(file).replace('fl_attachment', 'fl_attachment:false')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver documento
                </a>
              </>
            ) : (
              'No hay documento asociado.'
            )}
          </DialogContentText>
          <DialogContentText>
            {selectedAptoFile ? 'Elimina o sube un nuevo documento para este apartamento.' : 'No hay documento asociado.'}
          </DialogContentText>
          <input
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            style={{ marginTop: '10px' }}
          />
        </DialogContent>
        <DialogActions>
          {selectedAptoFile && (
            <Button onClick={handleDeleteFile} color="error">
              Eliminar Documento
            </Button>
          )}
          <Button onClick={handleUploadFile} disabled={!file}>
            Subir Documento
          </Button>
          <Button onClick={closeFileModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default ListadoApartamentos;
