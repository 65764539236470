import { gql } from "@apollo/client";

export const CREAR_PERSONA = gql`
  mutation CrearPersona($email: String!,$celular:String,$password:String!,$rol:Rol) {
    crearPersona(email: $email,celular:$celular,password:$password,rol:$rol) {
      email
      celular
      password
      rol
    }
  }
`;

export const EDITAR_PERSONA = gql`
  mutation editarPersona($personaId:ID!,$email: String!,$celular:String,$password:String!,$rol:Rol) {
    editarPersona(personaId:$personaId,email: $email,celular:$celular,password:$password,rol:$rol) {
      email
      celular
      password
      rol
    }
  }
`;

export const GET_PERSONAS = gql`
  query Personas {
    personas {
      _id
      email
      rol
      celular
      password
      empresa {
        nombre
      }
    }
  }
`;

export const ELIMINAR_PERSONA = gql`
  mutation EliminarPersona($personaId: ID!) {
    eliminarPersona(personaId: $personaId) {
      success
      message
    }
  }
`;