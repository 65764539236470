import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import './index.css'; // Asegúrate de importar el archivo CSS
import { LOGIN_MUTATION } from '../../Queries/credenciales';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = new URLSearchParams(location.search).get('redirect') || '/unidades';

  const [login, { loading, error }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      localStorage.setItem('token', data.login.token);
      navigate(redirectPath);
    },
  });

  const handleLogin = (e) => {
    e.preventDefault();
    login({ variables: { username, password } });
  };

  return (
      <div className="login-container">
        <div className='login'>
          <h2>Ingresar</h2>
          <form onSubmit={handleLogin}>
            <div className="login-input">
              <input 
                type="text" 
                placeholder='Usuario'
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
              />
            </div>
            <div className="login-input">
              <input 
                type="password" 
                placeholder='Contraseña'
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
            </div>
            <button type="submit" disabled={loading}>Login</button>
            {error?.message == 'User not found' && <p>Usuario Incorrecto, intente de nuevo.</p>}
            {/* <div className='container-registrarse'>
              <span>No tienes una cuenta? <Link to="/signup" style={{color:'#007bff'}}>Regístrate</Link></span>
            </div> */}
          </form>
        </div>
      </div>
  );
}

export default Login;
