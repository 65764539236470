import React, { useEffect, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Grid, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box } from '@mui/material';
import Modal from '../Modal';
import LayoutNBItems from './LayoutNBItems';
import { GET_EMPRESAS, CREAR_EMPRESA, EDITAR_EMPRESA, ELIMINAR_EMPRESA } from '../../Queries/empresas';

const Empresas = () => {
    const { data: dataEmpresas, refetch } = useQuery(GET_EMPRESAS);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [empresas, setEmpresas] = useState([]);
    const [empresa, setEmpresa] = useState('');
    const [email, setEmail] = useState('');
    const [celular, setCelular] = useState('');
    const [codigo, setCodigo] = useState('');
    const [editingId, setEditingId] = useState('');
    const [empresaToDelete, setEmpresaToDelete] = useState(null);

    const [crearEmpresa] = useMutation(CREAR_EMPRESA, {
        onCompleted: () => {
            refetch();
            closeModal();
        },
    });

    const [editarEmpresa] = useMutation(EDITAR_EMPRESA, {
        onCompleted: () => {
            closeModal();
        },
    });

    const [deleteEmpresa] = useMutation(ELIMINAR_EMPRESA, {
        onCompleted: () => {
            refetch();
            closeDeleteModal();
        },
    });

    useEffect(() => {
        refetch();
        if (dataEmpresas && dataEmpresas.empresas) {
            setEmpresas(dataEmpresas.empresas);
        }
    }, [dataEmpresas]);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setEditingId('');
        setEmpresa('');
        setEmail('');
        setCelular('');
        setCodigo('');
        refetch();
        setModalOpen(false);
    };

    const openDeleteModal = (id) => {
        setEmpresaToDelete(id);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setEmpresaToDelete(null);
        setDeleteModalOpen(false);
    };

    const handleEdit = (tarea) => {
        setEditingId(tarea._id);
        setEmpresa(tarea.nombre);
        setEmail(tarea.mail);
        setCelular(tarea.celular);
        setCodigo(tarea.codigo);
        openModal();
    };

    const handleDeleteConfirm = () => {
        if (empresaToDelete) {
            deleteEmpresa({ variables: { empresaId: empresaToDelete } });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (codigo.length !== 5) {
            alert('El código debe tener exactamente 5 dígitos.');
            return;
        }

        if (editingId) {
            editarEmpresa({
                variables: { empresaId: editingId, inputEmpresa: { nombre: empresa, mail: email, celular, codigo } },
            });
        } else {
            crearEmpresa({
                variables: { inputEmpresa: { nombre: empresa, mail: email, celular, codigo } },
            });
        }
    };

    return (
        <LayoutNBItems>
            <Typography variant="h4">Empresas</Typography>
            <Typography variant="body1">Administra las empresas contratadas.</Typography>
            <Button variant="contained" color="primary" onClick={openModal}>Nuevo</Button>
            {empresas.length > 0 ? (
                <TableContainer component={Paper} style={{ marginTop: 16 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Celular</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {empresas.map((item) => (
                                <TableRow key={item._id}>
                                    <TableCell>{item.nombre}</TableCell>
                                    <TableCell>{item.mail}</TableCell>
                                    <TableCell>{item.celular}</TableCell>
                                    <TableCell>
                                        <Button variant="outlined" onClick={() => handleEdit(item)}>Editar</Button>
                                        <Button variant="outlined" color="secondary" onClick={() => openDeleteModal(item._id)}>Eliminar</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="body1" style={{ marginTop: 16 }}>No hay ninguna empresa creada aún</Typography>
            )}

            {/* Modal para crear/editar empresa */}
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <form onSubmit={handleSubmit} style={{ padding: 20 }}>
                    <Typography variant="h6">{editingId ? 'Editar Empresa' : 'Crear Empresa'}</Typography>
                    <Grid container spacing={2} style={{ marginTop: 16 }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Empresa"
                                value={empresa}
                                onChange={(e) => setEmpresa(e.target.value)}
                                placeholder="Nombre de empresa"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Celular"
                                value={celular}
                                onChange={(e) => setCelular(e.target.value)}
                                placeholder="Cantidad"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Código"
                                onChange={(e) => setCodigo(e.target.value)}
                                placeholder="Ingrese 5 dígitos"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 5,
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" color="primary" style={{ marginTop: 16 }}>
                        {editingId ? 'Actualizar' : 'Guardar'}
                    </Button>
                </form>
            </Modal>

            {/* Modal de confirmación de eliminación */}
            <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h6" sx={{ color: '#000000' }}>Confirmar Eliminación</Typography>
                    <Typography variant="body1" sx={{ color: '#000000' }}>¿Estás seguro que deseas eliminar esta empresa?</Typography>
                    <Button onClick={handleDeleteConfirm} variant="contained" color="primary" sx={{ marginTop: 2 }}>Confirmar</Button>
                    <Button onClick={closeDeleteModal} variant="outlined" color="secondary" sx={{ marginTop: 2 }}>Cancelar</Button>
                </Box>
            </Modal>
        </LayoutNBItems>
    );
};

export default Empresas;
