import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Button, Typography, Snackbar } from '@mui/material';
import LayoutNBItems from './LayoutNBItems';
import { GET_APARTAMENTO, EDITAR_ADDRESS_APARTAMENTO } from '../../Queries/apartamentos';

const Address = ({ idApartamento }) => {
  const { id } = useParams();
  const apartmentId = idApartamento || id;

  const { data, loading, error } = useQuery(GET_APARTAMENTO, {
    variables: { id: apartmentId },
  });

  const [editarAddressApartamento] = useMutation(EDITAR_ADDRESS_APARTAMENTO);
  const [calle, setCalle] = useState('');
  const [numero, setNumero] = useState(0);
  const [num_apto, setNumApto] = useState('');
  const [datosAct, setDatosAct] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (data && data.apartamento) {
      setCalle(data.apartamento.calle);
      setNumero(data.apartamento.numero);
      setNumApto(data.apartamento.num_apto);
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    editarAddressApartamento({
      variables: {
        idApartamento,
        inputApartamento: {
          calle,
          numero: parseInt(numero, 10),
          num_apto,
        },
      },
    })
      .then(() => {
        setDatosAct('Datos Actualizados!');
        setOpenSnackbar(true);
        setTimeout(() => {
          setDatosAct('');
        }, 2000);
      })
      .catch((err) => {
        console.error('Error al actualizar los datos:', err);
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  if (loading) return <Typography variant="body1">Cargando...</Typography>;
  if (error) return <Typography variant="body1" color="error">Error al cargar los datos.</Typography>;

  return (
    <LayoutNBItems>
      <Typography variant="h4" gutterBottom>Editar dirección</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Calle"
              variant="outlined"
              value={calle}
              onChange={(e) => setCalle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Número"
              type="number"
              variant="outlined"
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Apartamento"
              variant="outlined"
              value={num_apto}
              onChange={(e) => setNumApto(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Guardar
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          open={openSnackbar}
          onClose={handleSnackbarClose}
          message={datosAct}
          autoHideDuration={2000}
        />
      </form>
    </LayoutNBItems>
  );
};

export default Address;
