import React, { useState } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_APARTAMENTOS_QUERY, CREAR_APARTAMENTO_MUTATION } from '../../Queries/apartamentos';
import { TextField, Button, Container, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

function CrearApartamento() {
  const [calle, setCalle] = useState('');
  const [numero, setNumero] = useState('');
  const [numApto, setNumApto] = useState('');
  const [mensajeExito, setMensajeExito] = useState(null);
  let navigate = useNavigate();

  const { refetch } = useQuery(GET_APARTAMENTOS_QUERY);

  const [crearApartamento, { loading, error }] = useMutation(CREAR_APARTAMENTO_MUTATION, {
    onCompleted: (data) => {
      setMensajeExito('Apartamento creado con éxito');
      setCalle('');
      setNumero('');
      setNumApto('');
      refetch();
      navigate("/unidades");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    crearApartamento({ variables: { calle, numero: parseInt(numero), num_apto: numApto } });
  };

  function handleBack() {
    navigate("/unidades");
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detectar si es pantalla móvil

  return (
    <Container sx={{marginLeft: isMobile ? '' : '200px',width:'80%'}}>
      <Box
        sx={{
          mt: 4,
          padding: isMobile ? '16px' : '24px',
          boxShadow: isMobile ? 'none' : 3,
          borderRadius: '8px',
          backgroundColor: '#fff',
          marginTop:'60px',
        }}
      >
        <Typography variant={isMobile ? 'h5' : 'h4'} component="h2" gutterBottom>
          Crear Apartamento
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Calle"
            variant="outlined"
            fullWidth
            margin="normal"
            value={calle}
            onChange={(e) => setCalle(e.target.value)}
            placeholder="Ingresa la calle"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Número de puerta"
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={numero}
            onChange={(e) => setNumero(e.target.value)}
            placeholder="Ingresa el número de puerta"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Apartamento"
            variant="outlined"
            fullWidth
            margin="normal"
            value={numApto}
            onChange={(e) => setNumApto(e.target.value)}
            placeholder="Ingresa el número de apartamento"
            InputLabelProps={{ shrink: true }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row', // Cambiar a columna en dispositivos móviles
              justifyContent: isMobile ? 'center' : 'space-between', // Alinear botones en móviles
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#E6F5EE', // Verde para crear
                color: '#069855',
              }}
              type="submit"
              disabled={loading}
              fullWidth={isMobile} // Para que sea ancho completo en móviles
            >
              {loading ? <CircularProgress size={24} /> : 'Crear Apartamento'}
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: '#f44336', // Rojo para cancelar
                borderColor: '#f44336', // Bordes rojos
                '&:hover': {
                  backgroundColor: '#ffebee', // Fondo rojo claro al pasar el mouse
                  borderColor: '#d32f2f', // Bordes más oscuros al pasar el mouse
                },
              }}
              onClick={handleBack}
              disabled={loading}
              fullWidth={isMobile} // Para que sea ancho completo en móviles
              style={{ marginLeft: isMobile ? '0' : '16px', marginTop: isMobile ? '16px' : '0' }} // Espaciado entre botones
            >
              Cancelar
            </Button>
          </Box>
          {error && <Alert severity="error" sx={{ mt: 2 }}>Error: {error.message}</Alert>}
          {mensajeExito && <Alert severity="success" sx={{ mt: 2 }}>{mensajeExito}</Alert>}
        </form>
      </Box>
    </Container>
  );
}

export default CrearApartamento;
