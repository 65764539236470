import React, { useState } from 'react';
import axios from 'axios';

const UploadPdf = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadUrl, setUploadUrl] = useState("");

  // Manejar el cambio del input de archivo
  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  // Función para subir el archivo a Cloudinary
  const handleUpload = async () => {
    if (!pdfFile) {
      alert("Por favor, selecciona un archivo PDF");
      return;
    }

    const formData = new FormData();
    formData.append('file', pdfFile);
    formData.append('upload_preset', 'pdfsApartamentos');  // Asegúrate de configurarlo en Cloudinary

    setUploading(true);

    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/dlkjp1hwa/raw/upload',  // 'raw' porque es un archivo
        formData
      );

      setUploadUrl(response.data.secure_url);
      console.log("Archivo subido:", response.data.secure_url);
    } catch (error) {
      console.error("Error subiendo archivo:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <h1>Subir PDF a Cloudinary</h1>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={uploading}>
        {uploading ? "Subiendo..." : "Subir PDF"}
      </button>

      {uploadUrl && (
        <div>
          <p>Archivo subido exitosamente:</p>
          <a href={uploadUrl} target="_blank" rel="noopener noreferrer">
            {uploadUrl}
          </a>
        </div>
      )}
    </div>
  );
};

export default UploadPdf;
