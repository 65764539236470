import React, { useEffect, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import Modal from '../Modal';
import LayoutNBItems from './LayoutNBItems';
import { GET_PERSONAS, CREAR_PERSONA, EDITAR_PERSONA, ELIMINAR_PERSONA } from '../../Queries/personas.js';
import { Button, Typography, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const Personas = () => {
    const { data: dataPersonas, refetch } = useQuery(GET_PERSONAS);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [personas, setPersonas] = useState([]);
    const [email, setEmail] = useState('');
    const [celular, setCelular] = useState('');
    const [password, setPassword] = useState('');
    const [editingId, setEditingId] = useState('');
    const [personaToDelete, setPersonaToDelete] = useState(null);

    const [crearPersona] = useMutation(CREAR_PERSONA, {
        onCompleted: () => {
            refetch();
            closeModal();
        },
    });

    const [editarPersona] = useMutation(EDITAR_PERSONA, {
        onCompleted: () => {
            refetch();
            closeModal();
        },
    });

    const [deletePersona] = useMutation(ELIMINAR_PERSONA, {
        onCompleted: () => {
            refetch();
            closeDeleteModal();
        },
    });

    useEffect(() => {
        refetch();
        if (dataPersonas && dataPersonas.personas) {
            setPersonas(dataPersonas.personas);
        }
    }, [dataPersonas]);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setEditingId(null);
        setEmail('');
        setCelular('');
        setPassword('');
    };

    const openDeleteModal = (persona) => {
        setPersonaToDelete(persona);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setPersonaToDelete(null);
        setDeleteModalOpen(false);
    };

    const handleEdit = (persona) => {
        setEditingId(persona._id);
        setEmail(persona.email);
        setCelular(persona.celular);
        setPassword(persona.password);
        openModal();
    };

    const handleDeleteConfirm = () => {
        if (personaToDelete) {
            deletePersona({ variables: { personaId: personaToDelete._id } });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (editingId) {
            editarPersona({ variables: { personaId: editingId, email, celular, password } });
        } else {
            crearPersona({ variables: { email, celular, password } });
        }
    };

    return (
        <LayoutNBItems>
            <Typography variant="h4">Personas</Typography>
            <Typography variant="body1">Administra las personas contratadas.</Typography>
            <Button onClick={openModal} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
                Nuevo
            </Button>
            {personas.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Rol</TableCell>
                                <TableCell>Empresa</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {personas
                            .filter((item) => item.email !== 'admin')
                            .map((item) => (
                                <TableRow key={item._id}>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.rol}</TableCell>
                                    <TableCell>{item.empresa && item.empresa.nombre ? item.empresa.nombre : 'Individual'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEdit(item)} variant="outlined" color="primary" sx={{ marginRight: 1 }}>
                                            Editar
                                        </Button>
                                        <Button onClick={() => openDeleteModal(item)} variant="outlined" color="secondary">
                                            Eliminar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="body1">No hay ninguna persona creada aún</Typography>
            )}

            {/* Modal para crear/editar persona */}
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Box component="form" onSubmit={handleSubmit} sx={{ padding: 2, color:'#000000' }}>
                    <Typography variant="h6">{editingId ? 'Editar Persona' : 'Crear Persona'}</Typography>
                    <Box sx={{ marginBottom: 2 }}>
                        <label htmlFor="mail">Usuario</label>
                        <input
                            type="text"
                            id="mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email (Obligatorio)"
                            style={{ width: '100%' }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <label htmlFor="celular">Celular</label>
                        <input
                            type="text"
                            id="celular"
                            onChange={(e) => setCelular(e.target.value)}
                            placeholder="Celular (Opcional)"
                            style={{ width: '100%' }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <label htmlFor="pass">Contraseña</label>
                        <input
                            type="password"
                            id="pass"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Contraseña (Obligatorio)"
                            style={{ width: '100%' }}
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary">
                        {editingId ? 'Actualizar' : 'Guardar'}
                    </Button>
                </Box>
            </Modal>

            {/* Modal de confirmación de eliminación */}
            <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                <Box display="flex" flexDirection="column" alignItems="center" sx={{ padding: 2 }}>
                    <Typography variant="h6" sx={{ color: '#000000' }}>Confirmar Eliminación</Typography>
                    <Typography variant="body1" sx={{ color: '#000000', marginBottom: 2 }}>
                        ¿Estás seguro de que deseas eliminar a {personaToDelete ? personaToDelete.email : 'esta persona'}?
                    </Typography>
                    <Button onClick={handleDeleteConfirm} variant="contained" color="primary" sx={{ marginBottom: 1 }}>
                        Confirmar
                    </Button>
                    <Button onClick={closeDeleteModal} variant="outlined" color="secondary">
                        Cancelar
                    </Button>
                </Box>
            </Modal>
        </LayoutNBItems>
    );
};

export default Personas;
