import { gql } from "@apollo/client";

export const CREAR_EMPRESA = gql`
  mutation CrearEmpresa($inputEmpresa: InputEmpresa!) {
    crearEmpresa(inputEmpresa: $inputEmpresa) {
      nombre
      mail
      celular
      codigo
    }
  }
`;

export const EDITAR_EMPRESA = gql`
  mutation editarEmpresa($empresaId:ID!,$inputEmpresa: InputEmpresa!) {
    editarEmpresa(empresaId:$empresaId,inputEmpresa: $inputEmpresa) {
      nombre
      mail
      celular
      codigo
    }
  }
`;

export const GET_EMPRESAS = gql`
  query Empresas {
    empresas {
      _id
      nombre
      mail
      celular
      codigo
      personas {
        email
        rol
      }
    }
  }
`;

export const ELIMINAR_EMPRESA = gql`
  mutation EliminarEmpres($empresaId: ID!) {
    eliminarEmpresa(empresaId: $empresaId) {
      success
      message
    }
  }
`;