import { gql } from '@apollo/client';

export const GET_AMBIENTES = gql`
  query GetAmbientes($id: ID!) {
    ambientes(apartamentoId: $id) {
      _id
      nombre
      detalles
    }
  }
`;
