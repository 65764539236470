import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Inventory from './Inventory';
import TaskList from './TaskList';
import Address from './Address';
import Ambiente from './Ambiente';
import { useParams } from 'react-router-dom';
import {useQuery } from '@apollo/client';
import { GET_APARTAMENTO_QUERY } from '../../Queries/apartamentos';

const EditarApartamento = () => {
  const { id } = useParams();
  const [activeComponent, setActiveComponent] = useState('address');

  const { loading, error } = useQuery(GET_APARTAMENTO_QUERY, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    // Redirigir automáticamente a 'address' si la ruta es '/editarApartamento/:id'
    if (!activeComponent) {
      setActiveComponent('address');
    }
  }, [activeComponent]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const renderComponent = () => {
    switch (activeComponent) {
      case 'inventory':
        return <Inventory idApartamento={id}/>;
      case 'task-list':
        return <TaskList idApartamento={id}/>;
      case 'address':
        return <Address idApartamento={id}/>;
      case 'ambientes':
        return <Ambiente idApartamento={id}/>
      default:
        return <Address idApartamento={id} />;
    }
  };

  return (
    <div >
      <Navbar onSelect={setActiveComponent} activeComponent={activeComponent} />
      {renderComponent()}
    </div>
  );
};

export default EditarApartamento;
