import { gql } from "@apollo/client";

export const GET_ALL_INSPECCIONES = gql`
  query GetInspecciones {
    inspecciones {
        _id
        inicio
        fin
        apartamento {
          calle
          numero
          num_apto
        }
        empleado {
          nombre
        }
    }
  }
`;

export const GET_DETALLE_INSPECCION = gql`
  query GetInspeccion($id: ID!) {
    inspeccion(id: $id) {
      persona {
        email
      }
      tareas {
        nombre
        descripcion
        completada
        comentario
      }
    }
  }
`;

export const ELIMINAR_INSPECCIONES = gql`
  mutation eliminarInspecciones($inspecciones: [ID!]!) {
    eliminarInspecciones(inspecciones: $inspecciones) {
      success
      message
    }
  }
`;