import React from 'react';
import '../../Styles/Navbar.css';

const Navbar = ({ onSelect, activeComponent }) => {
  return (
    <div className="navbar">
            <button
        className={activeComponent === 'address' ? 'active' : ''}
        onClick={() => onSelect('address')}
      >
        Dirección
      </button>
      <button
        className={activeComponent === 'ambientes' ? 'active' : ''}
        onClick={() => onSelect('ambientes')}
      >
        Ambientes
      </button>
      <button
        onClick={() => onSelect('inventory')}
        className={activeComponent === 'inventory' ? 'active' : ''}
      >
        Inventario
      </button>
      <button
        className={activeComponent === 'task-list' ? 'active' : ''}
        onClick={() => onSelect('task-list')}
      >
        Tareas
      </button>
    </div>
  );
};

export default Navbar;
