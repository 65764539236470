import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { GET_DETALLE_INSPECCION } from "../../Queries/Inspeccion";
import {
  Container,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LayoutNBItems from "./LayoutNBItems";

const DetalleInspeccion = () => {
  const { id } = useParams();
  const [filtroCompletada, setFiltroCompletada] = useState(null); // Estado para el filtro

  // Llama a los Hooks al inicio del componente
  const theme = useTheme(); // Hook para acceder al tema
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Hook para verificar el tamaño de la pantalla

  const { loading, error, data } = useQuery(GET_DETALLE_INSPECCION, {
    variables: { id },
  });

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">Error: {error.message}</Alert>;

  // Filtrar las tareas basadas en el estado seleccionado
  const tareasFiltradas = data.inspeccion.tareas.filter((tarea) => {
    if (filtroCompletada === null) return true;
    return tarea.completada === filtroCompletada;
  });

  return (
    <LayoutNBItems>
            <Container
      sx={{
        mt: 4,
        transition: 'margin-left 0.3s ease',  // Para hacer una transición suave cuando el tamaño cambia
      }}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h4" component="h1">
            Detalle de tareas de la inspección
          </Typography>
          <Typography variant="subtitle1">
            {/* Inspección realizada por <strong>{data.inspeccion.persona.email}</strong> */}
          </Typography>
        </Grid>
 
        <Grid item>
          <RadioGroup
            row={!isSmallScreen} // Si es pantalla pequeña, muestra en columna, si no, en fila
            value={filtroCompletada === null ? 'todas' : filtroCompletada === true ? 'completadas' : 'sin_hacer'}
            onChange={(e) => {
              const value = e.target.value;
              setFiltroCompletada(
                value === 'todas' ? null : value === 'completadas' ? true : false
              );
            }}
          >
            <FormControlLabel value="todas" control={<Radio />} label="Todas" />
            <FormControlLabel value="completadas" control={<Radio />} label="Realizadas" />
            <FormControlLabel value="sin_hacer" control={<Radio />} label="Sin hacer" />
          </RadioGroup>
        </Grid>

        <Grid item>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tarea</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tareasFiltradas.map((tarea, index) => (
                  <TableRow key={index}>
                    <TableCell>{tarea.nombre}</TableCell>
                    <TableCell>{tarea.descripcion || "Sin descripción"}</TableCell>
                    <TableCell>{tarea.completada ? "Realizada" : "Sin hacer"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
    </LayoutNBItems>

  );
};

export default DetalleInspeccion;
