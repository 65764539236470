import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { VERIFY_TOKEN_QUERY } from '../../Queries/credenciales';

function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');

  const { loading, error } = useQuery(VERIFY_TOKEN_QUERY, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    skip: !token,
    onError: (err) => {
      navigate(`/?redirect=${encodeURIComponent(location.pathname)}`);
    },
  });

  useEffect(() => {
    if (!token || error) {
      navigate(`/?redirect=${encodeURIComponent(location.pathname)}`);
    }
  }, [token, error, navigate, location.pathname]);

  if (loading) return <p>Loading...</p>;
  if (!token || error) return null;

  return children;
}

export default ProtectedRoute;
